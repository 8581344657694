<template>
  <ContentSection :spacing="false" class="mt-2">
    <section class="content-spacing">
      <div class="flex items-center justify-between">
        <TitlePlus :title="`History`" :hidePlus="true" />
        <DateRangePicker
          @apply-date="onApplyFilterDateRange"
          @cancel-date="onApplyFilterDateRange"
        />
      </div>

      <div class="summary-card-container">
        <SummaryCard
          title="Promo Users"
          :value="getSummaryUsers"
          variant="purple"
        />

        <SummaryCard
          title="Trips Taken"
          :value="getSummaryTrips"
          variant="green"
        />

        <SummaryCard
          title="Discounted Amount"
          :value="getSummaryDiscounts"
          variant="blue"
        />
      </div>
    </section>

    <FSTable
      :fst-id="`promoDetailsHistory`"
      :headers="tableHeaders"
      :endpoint="`/dashboard/promotions/${$route.params.id}/user-promotions/`"
      @meta="(e) => (indexMetaData = e)"
    >
      <template #default="{ data }">
        <FSTableRow
          v-for="(item, itemIndex) in data"
          :key="`fs-table-row-${itemIndex}`"
          text-fallback-always
        >
          <FSTableRowItem :text="item.id" :truncate="-5" />

          <FSTableRowItem
            :text="item.user_full_name"
            :to="{
              name: 'ViewRiderUserProfile',
              params: { id: item.user_id },
            }"
            target="_blank"
          />

          <FSTableRowItem :text="item.num_of_trips_availed" />

          <FSTableRowItem :text="item.num_of_trips_left" />

          <FSTableRowItem
            :text="getFormattedDateTime(item.created_at, 'D MMM, YYYY h:m a')"
          />

          <FSTableRowItem :text="getDiscountAmount(item.discount_amount)" />
        </FSTableRow>
      </template>
    </FSTable>
  </ContentSection>
</template>

<script>
import TitlePlus from '@/components/ui/TitlePlus.vue'
import ContentSection from '@/components/layout/ContentSection'
import SummaryCard from '@/components/cards/SummaryCard'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import { getFormattedDateTime } from '@/utils/datetime'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  // FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

export default {
  name: 'Vehicles',
  components: {
    ContentSection,
    TitlePlus,
    SummaryCard,
    DateRangePicker,
    FSTable,
    FSTableRow,
    FSTableRowItem,
    // FSTableFilter,
  },
  props: {
    id: {
      required: false,
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          promo_users: 0,
          total_discounted: 0,
          trip_taken: 0,
        },
      },
      promoTypeChoice: {
        FR: 'Free Ride',
        FD: 'Flat Discount',
        RD: 'Rate Discount',
      },
      tableHeaders: [
        { text: 'ID', width: '10%', sort: 'id' },
        { text: 'Rider Name', width: '20%', sort: 'user_full_name' },
        { text: 'Trip Availed', width: '15%', sort: 'num_of_trips_availed' },
        { text: 'Trip Left', width: '15%', sort: 'num_of_trips_left' },
        { text: 'Date', width: '20%', sort: 'created_at' },
        { text: 'Used Discount Amount', width: '20%', sort: 'discount_amount' },
      ],
      total_trip_taken: '',
      discount_amount: '',
      start_date: '',
    }
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getSummaryUsers() {
      return this.indexMetaData.summary.promo_users
    },
    getSummaryTrips() {
      return this.indexMetaData.summary.trip_taken
    },
    getSummaryDiscounts() {
      return `${this.currencySymbol} ${this.indexMetaData.summary.total_discounted}`
    },
  },
  methods: {
    getFormattedDateTime,
    onApplyFilterDateRange,
    getPromoTypeChoice(choice) {
      return choice in this.promoTypeChoice ? this.promoTypeChoice[choice] : '-'
    },
    getDiscountAmount(amount) {
      return this.currencySymbol + ' ' + parseFloat(amount).toFixed(2)
    },
  },
}
</script>

<style lang="scss" scoped>
.promo-free-card {
  width: 220px;
  height: 80px;
  border-radius: 5px;
  background-color: #a600ff;
}
.promo-discount-card {
  width: 220px;
  height: 80px;
  border-radius: 5px;
  background-color: #ea8900;
  margin-left: 15px;
}
.promo-flat-card {
  width: 220px;
  height: 80px;
  border-radius: 5px;
  background-color: #23c977;
  margin-left: 15px;
}
.promo-card-title {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.promo-card-value {
  font-size: 23px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.promo-card-right {
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.promotion-header {
  span {
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e39;
  }
}
</style>
