<template>
  <base-layout>
    <div
      v-if="$acl.canNotView('promotions')"
      class="py-5 font-bold text-center text-gray-600"
    >
      You don't have permission to see data. Please Ask your admin to give you
      permission for this screen.
    </div>
    <template v-else>
      <div v-if="!promo" class="h-screen vld-parent">
        <loading :active="true" :is-full-page="false" :z-index="20" />
      </div>

      <template v-else>
        <details-page-title
          title="Promo Management"
          dropdown-text-button="Promo Status"
          :details-id="$truncate($route.params.id, -5)"
        >
          <div class="flex items-center gap-4">
            <button
              @click="executeAction('notifyRiders')"
              class="
                flex
                items-center
                justify-between
                px-4
                py-0
                font-medium
                border
                rounded-full
                shadow-sm                
                h-35px
                text-14px text-oWhite
                focus:border-border-oWhite                
                focus:outline-none
                
              "
              :class="[
                promo.status === 'Active'
                  ? 'bg-blue-600 hover:bg-blue-700'
                  : 'bg-gray-400 hover:bg-gray-500 cursor-not-allowed',
              ]"
            >
              <div class="flex items-center ">
                <i class="mr-2 fas fa-paper-plane"></i>
                <span> Notify Riders </span>
              </div>
            </button>
            <MoreActions
              :current-status="promo.status"
              @change="onChangePromoStatus"
            />
          </div>

          <!-- <MoreActions
            :current-status="promo.is_active"
            @change="onChangePromoStatus"
          /> -->
        </details-page-title>

        <div class="flex flex-col py-8 px-4 mt-2 bg-white rounded md:flex-row">
          <promo-card
            :class="`w-full md:w-1/3`"
            :type="getPromoCategory"
            :title="getPromoCategoryName(getPromoCategory)"
            :code="promo.code"
            :percent="
              `${
                promo.percentage ? parseFloat(promo.percentage).toFixed(0) : '0'
              }%`
            "
            :flat="
              `${currencySymbol}${
                promo.flat_discount_amount
                  ? parseFloat(promo.flat_discount_amount).toFixed(0)
                  : '0'
              }`
            "
          />

          <section
            class="
            px-2
            mt-10
            md:grid md:grid-cols-3 md:mt-0 md:gap-5 md:ml-20 md:w-2/3
          "
          >
            <div
              v-for="(columns, columnsIndex) in getPromoDetails"
              :key="columnsIndex"
              class=""
            >
              <div
                v-for="item in columns"
                :key="item.text"
                class="
                flex flex-row
                justify-between
                mt-5
                md:flex-col md:justify-start
              "
              >
                <div class="w-1/3 md:w-full view-detail-title">
                  {{ item.text }}
                </div>

                <div
                  v-if="item.text === 'Assigned Fleets'"
                  class="w-2/3 md:w-full view-detail-value"
                >
                  <div class="flex items-center gap-2 flex-wrap">
                    <RouterLink
                      class="text-blue-600"
                      v-for="(fleet, key) in item.value"
                      :key="key"
                      :to="{
                        name: 'ViewFleetProfile',
                        params: { id: fleet.id },
                      }"
                      target="_blank"
                      >{{ fleet.name }}</RouterLink
                    >
                  </div>
                </div>
                <div v-else class="w-2/3 md:w-full view-detail-value">
                  {{ item.value }}
                </div>
              </div>
            </div>
          </section>
        </div>

        <PromotionDetailsHistory :id="$route.params.id" />
      </template>
      <NotifyModal
        :id="`notify-${promo.id}`"
        :key="`notify-${promo.id}`"
        :isLoading="isLoading"
        :title="`Please Select your push medium*`"
        :subtitle="`Please select notification channels (SMS, Push, Email)`"
        :cancelButtonText="`Cancel`"
        :cancelButtonVariant="`secondary`"
        :notify-button-text="`Notify`"
        :notify-button-variant="`green`"
        @setQueryString="(query) => (queryString = query)"
        @notify="actionNotify"
        @cancel="executeAction('hideNotifyModal')"
      />
    </template>
  </base-layout>
</template>

<script>
import { PromoConfig } from '@/config/PromoConfig'
import { getPromoCategoryName } from '@/utils'

import BaseLayout from '@/views/shared/BaseLayout'
import PromoCard from '@/components/cards/PromoCard'
import PromotionDetailsHistory from './PromotionDetailsHistory'
import MoreActions from './MoreActions'
import DetailsPageTitle from '@/components/ui/DetailsPageTitle.vue'
import { useEndpoints } from '@/composables'

export default {
  name: 'PromotionDetails',
  components: {
    BaseLayout,
    PromoCard,
    PromotionDetailsHistory,
    DetailsPageTitle,
    MoreActions,
    NotifyModal: () => import('@/components/modals/NotifyModal'),
  },
  data() {
    return {
      reqBusy: false,
      promo: null,
      isLoading: false,
      queryString: null,
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getPromoCategory() {
      return this.promo.promotion_category
    },
    getPromoFleets() {
      const fleets = [...this.promo?.assigned_fleets]
      if (!fleets?.length) {
        return '--'
      }
      return fleets
    },
    getPromoFleetId() {
      return ''
    },
    getPromoDetails() {
      const promo = { ...this.promo }
      const promoCat = promo?.promotion_category
      const dateDiff = this.$dayjs(promo.end_date).diff(promo.start_date, 'day')
      const dateDiffUnit = dateDiff === 0 || dateDiff === 1 ? 'day' : 'days'

      const data = [
        // col-1
        [
          { text: 'Promo Name', value: promo.title },
          {
            text: 'Promo Type',
            value: this.getPromoCategoryName(promoCat),
          },
          {
            text: 'Promo Description',
            value:
              promo.description && promo.description.length > 60
                ? promo.description.slice(0, 60) + ' ...'
                : promo.description,
          },
        ],
        // col-2
        [
          {
            text: 'Promo Start Date',
            value: this.$localTime(promo.start_date, 'DD MMM, YYYY hh:mm a'),
          },
          {
            text: 'Promo Expire Date',
            value: this.$localTime(promo.end_date, 'DD MMM, YYYY hh:mm a'),
          },
          {
            text: 'Promo Validity Duration',
            value: `${dateDiff} ${dateDiffUnit}`,
          },
        ],
        /*
         * FR -> num_of_trips + daily_used
         * FD -> FR + flat_discount_amount + min_travel_time_in_sec
         * RD -> FR + percentage + min_travel_time_in_sec + max_amount
         **/
        // col-3
        [
          {
            text: 'Assigned Fleets',
            value: this.getPromoFleets,
          },
          { text: 'Daily Usage Limit', value: promo.daily_used },
          { text: 'Total Trips Limit', value: promo.num_of_trips },
        ],
      ]
      if (promoCat === 'FD') {
        data.push(
          [
            {
              text: 'Flat Discount Amount',
              value: this.currencySymbol + promo.flat_discount_amount || '--',
            },
          ],
          [
            {
              text: 'Min Trip Time',
              value: promo.min_travel_time_in_sec + ' sec.' || '--',
            },
          ]
        )
      } else if (promoCat === 'RD') {
        data.push(
          [
            {
              text: 'Discount Percentage',
              value: parseFloat(promo.percentage).toFixed(0) + '%' || '--',
            },
          ],
          [
            {
              text: 'Min Trip Time',
              value: promo.min_travel_time_in_sec + ' sec.' || '--',
            },
          ],
          [
            {
              text: 'Max Discount Amount',
              value: this.currencySymbol + promo.max_amount || '--',
            },
          ]
        )
      }
      data.push([
        {
          text: 'Target Rider Group',
          value:
            this.promo.eligible_point_categories.length > 0
              ? this.promo.eligible_point_categories.join(', ')
              : 'All',
        },
      ])
      return data
    },
  },
  async created() {
    if (this.$acl.canView('promotions')) {
      await this.fetchData()
    }
  },
  methods: {
    getPromoCategoryName,
    async fetchData() {
      this.reqBusy = true
      this.promo = await this.$http
        .get(PromoConfig.api.detail(this.id))
        .then((res) => res.data)
        .catch((err) => console.log(err))
        .finally((this.reqBusy = false))
    },
    async actionNotify() {
      this.isLoading = true
      const url =
        useEndpoints.promo.notifyRiders(this.promo.id) + this.queryString

      await this.$http
        .post(url)
        .then((res) => {
          console.log('notifySuccess--', { res })
          this.$modal.hide(`notify-${this.promo.id}`)
          this.$notify(
            {
              group: 'generic',
              type: 'success',
              title: 'Successful',
              text: res.data.message,
            },
            3000
          )
        })
        .catch((err) => {
          console.log('notifyErr--', { err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Error Occurred [${err.response.status}]`,
              text: err.response.data.message,
            },
            3000
          )
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    executeAction(type) {
      if (this.$acl.canNotUpdate('promotions')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'notify',
            permission: 'this promotions',
          })
        )
        return
      }
      if (type === 'notifyRiders') {
        if (['Expired', 'Inactive'].includes(this.promo.status)) {
          if (this.promo.status === 'Expired') {
            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Expired Promotion!',
                text: "You can't notify riders for expired Promotion.",
              },
              5000
            )
          }
          if (this.promo.status === 'Inactive') {
            this.$notify(
              {
                group: 'generic',
                type: 'default',
                title: 'Inactive Promotion!',
                text:
                  "You can't notify riders for inactive Promotion. Please activate the Promotion first.",
              },
              5000
            )
          }
          return
        } else {
          this.$modal.show(`notify-${this.promo.id}`)
        }
      }
      if (type === 'hideNotifyModal') {
        this.$modal.hide(`notify-${this.promo.id}`)
      }
    },
    async onChangePromoStatus(e) {
      if (this.$acl.canNotUpdate('promotions')) {
        this.$notify(
          this.$acl.notAbleToToast({
            action: 'update',
            permission: 'promotions',
          })
        )
        return
      }
      const data = new FormData()
      data.append('is_active', e)

      await this.$http
        .patch(PromoConfig.api.update(this.id), data)
        .then(() => {
          this.fetchData()
          this.$notify({
            group: 'generic',
            type: 'success',
            title: 'Promo Status Updated',
            text: 'Promo status updated successfully',
          })
        })
        .catch((err) => {
          this.promo.is_active = false
          this.$notify({
            group: 'generic',
            type: 'error',
            title: `Failed [Error:${err.response.status}]`,
            text: `${err.response.data.detail}`,
          })
          console.log(err)
        })
        .finally()
    },
  },
}
</script>

<style lang="scss" scoped>
.promotion-status {
  width: 230px;
  height: 50px;
  background: white;
  box-shadow: 0px 0px 4px 2px #e4dfdf;
  border-radius: 25px;
  span {
    font-family: Roboto;
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #15151b;
    margin-right: 8px;
  }
  t-toggle {
    font-size: 20px;
  }
  h3 {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #2e2e39;
  }
}
</style>
